import "./App.css";
import { useState } from "react";
import PolygonIDVerifier from "./PolygonIDVerifier";
import VcGatedContent from "./VcGatedContent";
import { Center, Card, Image, CardBody, Container } from "@chakra-ui/react";

function App() {
  const [provedCredential, setProvedCredential] = useState(false);

  return (
    <Center className="App">
      <Container>
        {provedCredential ? (
          <VcGatedContent />
        ) : (
          <Card
            style={{
              border: "2px solid #805AD5",
              background: "",
              color: "black",
            }}
          >
            <CardBody style={{ paddingBottom: 0 }}>
              <p>Verifiable Credential Verifier</p>
              <h2>Generate your verification query</h2>

              <PolygonIDVerifier
                serverURL={process.env.REACT_APP_VERIFICATION_SERVER_URL}
                onVerificationResult={setProvedCredential}
              />
              <Image
                src="https://github.com/ey-apac-mufj/vc-schema-repo/blob/001715171befb68630124f1d82ecf7eeef9a1666/palmtree.png?raw=true"
                alt="Polygon devs image"
                borderRadius="lg"
              />
            </CardBody>
            <a
              href="https://twitter.com/0ceans404"
              target="_blank"
              rel="noreferrer"
            >
              <p
                style={{
                  position: "absolute",
                  bottom: "-15px",
                  right: "0",
                  fontSize: "8px",
                }}
              ></p>
            </a>
          </Card>
        )}
      </Container>
    </Center>
  );
}

export default App;
